import { eventsNames } from './helpers/eventsConstants';
import { VTO_VERSION } from '../ShadeFinder/helpers/constants';
import { pushEvent } from '../ShadeFinder/tracking';
import { trackingVtoTypes } from './helpers';

const eventGenerator = ({ eventName, vtoType }) => {
  switch (eventName) {
    case eventsNames.VTO_EVENT_CLOSED:
      return {
        event: 'basic_event',
        event_category: 'try on',
        event_action: 'exit confirmed',
        event_label: 'exit confirmed',
        page_name: 'try on',
        app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
      };

    case eventsNames.VTO_GENERIC_EVENT_CAMERA_OPEN:
      return {
        event: 'basic_event',
        event_category: 'camera access',
        event_action: 'camera access allow',
        event_label: 'camera access allow',
        page_name: 'camera access',
        app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
      };

    case eventsNames.VTO_GENERIC_EVENT_CAMERA_CLOSED:
      return {
        event: 'basic_event',
        event_category: 'camera access',
        event_action: 'camera access closed',
        event_label: 'camera access closed',
        page_name: 'camera access',
        app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
      };

    case eventsNames.VTO_ERROR_CAMERA_FAILED:
      return {
        event: 'error',
        event_category: 'camera access',
        event_action: 'camera access blocked',
        event_label: 'camera access blocked',
        page_name: 'camera access',
        app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
      };

    case eventsNames.VTO_LOADING:
      return {
        event: 'page_view',
        page_name: 'loading',
        app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
      };

    case eventsNames.VTO_PRODUCT_EVENT_SLIDER_COMPARE:
      return {
        event: 'basic_event',
        event_category: 'try on',
        event_action: 'tooltip clicked',
        event_label: 'compare',
        page_name: 'try on',
        app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
      };

    case eventsNames.VTO_PRODUCT_EVENT_SLIDER_COMPARE_CLOSED:
      return {
        event: 'basic_event',
        event_category: 'try on',
        event_action: 'tooltip clicked',
        event_label: 'compare disabled',
        page_name: 'try on',
        app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
      };

    case eventsNames.VTO_PRODUCT_EVENT_RESET:
      return {
        event: 'basic_event',
        event_category: 'try on',
        event_action: 'tooltip clicked',
        event_label: 'reset',
        page_name: 'try on',
        app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
      };

    case eventsNames.VTO_PRODUCT_EVENT_SPLIT:
      return {
        event: 'basic_event',
        event_category: 'try on',
        event_action: 'tooltip applied',
        event_label: 'split screen',
        page_name: 'try on',
        app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
      };

    case eventsNames.VTO_DOWNLOAD_PICTURE:
      return {
        event: 'basic_event',
        event_category: 'try on',
        event_action: 'vto download picture open',
        event_label: 'vto download picture open',
        page_name: 'try on',
        app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
      };

    case eventsNames.VTO_DOWNLOAD_PICTURE_DOWNLOAD:
      return {
        event: 'basic_event',
        event_category: 'try on',
        event_action: 'vto download picture download',
        event_label: 'vto download picture download',
        page_name: 'try on',
        app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
      };

    case eventsNames.VTO_SHARE_EMAIL:
      return {
        event: 'basic_event',
        event_category: 'try on',
        event_action: 'vto share email open',
        event_label: 'vto share email open',
        page_name: 'try on',
        app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
      };

    case eventsNames.VTO_SHARE_EMAIL_CONFIRM:
      return {
        event: 'basic_event',
        event_category: 'try on',
        event_action: 'vto share email confirm',
        event_label: 'vto share email confirm',
        page_name: 'try on',
        app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
      };

    case eventsNames.VTO_SHARE_EMAIL_SEND:
      return {
        event: 'basic_event',
        event_category: 'try on',
        event_action: 'vto share email send',
        event_label: 'vto share email send',
        page_name: 'try on',
        app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
      };

    default:
      return {};
  }
};

export const dispatchEvent =
  ({ vtoType }) =>
  ({ eventName }) => {
    const event = eventGenerator({
      eventName,
      vtoType,
    });

    pushEvent({ event });
  };
